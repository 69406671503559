import React from 'react';
import styled from 'styled-components';
import {ReactComponent as GridIcon} from 'assets/icons/grid.svg';
import {ReactComponent as MapIcon} from 'assets/icons/map.svg';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const DisplayModeButtonWrapper = styled.div`

	padding: unset;
	background-color: white;
	

	button {
		color: ${p => p.theme.colors.ascent.primary};
		cursor: pointer;
		position: relative;
		height: 50px;
		font-size: 17px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: -0.427179px;
		border: unset;
		background-color: white;
		width: 100%;

		&:hover {
			background-color: ${p => p.theme.colors.ascent.primary};
			color: white;	
		}

		@media ${device.laptop} { 
			width: 238px;
			height: 100px;
			font-weight: 600;
		}
	}

`;

const GridViewButton = styled.div`
	display: flex;
	flex-direction: row;
    justify-content: center;
    align-items: center;
	height: 100%;

	svg {
		align-self: center;
		margin-right: 12px;
	}

	&:hover {
		svg {
			fill: ${p => p.theme.colors.ascent.primary};
			rect {
				stroke: ${p => p.theme.colors.white.primary};
			}
		}
	}
`;

const MapViewButton = styled.div`
	display: flex;
	flex-direction: row;
    justify-content: center;
    align-items: center;
	height: 100%;

	svg {
		stroke: ${p => p.theme.colors.ascent.primary};
		align-self: center;
		margin-right: 12px;
	}

	&:hover {
		svg {
			stroke: ${p => p.theme.colors.white.primary};
			fill: ${p => p.theme.colors.ascent.primary};

		}
	}
`;

const DisplayModeButton = (props) => {
	const { gridViewMode, setGridViewMode } = props;
	const { t } = useTranslation();
	
	const handleClick = () => {
		setGridViewMode(!gridViewMode);
	};

	let visibleButton;
	if (!gridViewMode) {
		visibleButton = <GridViewButton><GridIcon /> <div>{t('tenancies.viewOptions.grid')}</div></GridViewButton>;
	} else {
		visibleButton = <MapViewButton><MapIcon /><div>{t('tenancies.viewOptions.map')}</div></MapViewButton>;
	}

	return (
		<DisplayModeButtonWrapper onClick={handleClick}>
			{visibleButton}
		</DisplayModeButtonWrapper>
	);
};

DisplayModeButton.propTypes = {
	gridViewMode: PropTypes.bool.isRequired,
	setGridViewMode: PropTypes.func.isRequired,
};

export default DisplayModeButton;