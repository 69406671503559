import React from 'react';
import styled from 'styled-components';
import useKeypress from 'components/useKeyPress';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import da from 'date-fns/locale/da';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
registerLocale('da', da);


const SideDrawerWrapper = styled.nav`
	min-width: 280px;
	height: 100vh;
	z-index: 1000;
	position: fixed;
	background-color: white;
	transform: translateX(-100%);
	transition: transform 0.3s ease-out;
	padding: 24px;
    box-sizing: border-box;
	overflow-y: scroll;
	@media ${device.laptop} { 
		width: 400px;
		padding: 24px 48px;
	}


	&.visible {
		transform: translateX(0);
	}

	div.title {
		font-size: 18px;
		padding: 48px 24px;
		text-align: center;

		svg {
			padding-right: 16px;
		}
	}

	.filter {
		padding: 24px 0px;

		input[type='checkbox'] {
			margin: 10px;
		}
	}

	.slider-wrapper {
		padding: 12px 0px;
		height: 24px;
		width: 100%;
	}

	.slider-thumb {
		color: ${p => p.theme.colors.white.primary};
		background: ${p => p.theme.colors.ascent.primary};
		border-radius: 12px;
		padding: 12px;
	}
	.slider-track {
		top: 22px;
		height: 4px;
		background: ${p => p.theme.colors.off.primary};
	}

	.slider-track-1 {
		background: ${p => p.theme.colors.ascent.secondary};
	}
	.filter-value {
		text-align: center;
	}

	.features-filter {
		/* The container */
		.container {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-top: 8px;
		margin-bottom: 12px;
		cursor: pointer;
		
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		}

		/* Hide the browser's default checkbox */
		.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		}

		/* Create a custom checkbox */
		.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		}

		/* On mouse-over, add a grey background color */
		.container:hover input ~ .checkmark {
		background-color: #ccc;
		}

		/* When the checkbox is checked, add a blue background */
		.container input:checked ~ .checkmark {
		background-color: ${p => p.theme.colors.ascent.primary};
		}

		/* Create the checkmark/indicator (hidden when not checked) */
		.checkmark:after {
		content: "";
		position: absolute;
		display: none;
		}

		/* Show the checkmark when checked */
		.container input:checked ~ .checkmark:after {
		display: block;
		}

		/* Style the checkmark/indicator */
		.container .checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		}
	}

	.availability-filter {
		.react-datepicker-wrapper {
			padding: 12px 0px;

			input {
				height: 24px;
				padding: 4px 8px;
				font-size: 16px;
				border: 1px solid ${p => p.theme.colors.ascent.primary};
				border-radius: 3px;
			}

			
		}
		.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
			background-color: ${p => p.theme.colors.ascent.primary};
		}
		.react-datepicker__header {
			background-color: ${p => p.theme.colors.ascent.primary};
		}
		.react-datepicker__current-month, .react-datepicker__day-name {
			color: white;
		}
	}
`;

const Backdrop = styled.div`
	width: 100vw;
	height: 100vh;
	display: none;
	position: fixed;
	background-color: #0000006b;
	z-index: 900;

	&.visible {
		display: block;
	}
`;

const AttributesSideDrawer = (props) => {

	const { 
		visible, 
		setVisible, 
		balconyFilterValue,
		setBalconyFilterValue,
		petsFilterValue,
		setPetsFilterValue,
		elevatorFilterValue,
		setElevatorFilterValue,
	} = props;
	const { t } = useTranslation();

	const handleBackdropClick = () => {
		setVisible(false);
	};

	useKeypress('Escape', () => {
		if (visible) {
			setVisible(false);
		}
	});

	const onBalconyFilterChange = () => {
		setBalconyFilterValue(!balconyFilterValue);
	};
	const onPetsFilterChange = () => {
		setPetsFilterValue(!petsFilterValue);
	};
	const onElevatorFilterChange = () => {
		setElevatorFilterValue(!elevatorFilterValue);
	};

	return (
		<>
			<Backdrop onClick={handleBackdropClick} className={visible ? 'visible' : ''} />
			<SideDrawerWrapper className={visible ? 'visible' : ''}>
				<div className="title"><FilterIcon />{t('filters.title')}</div>
				<div className="features-filter filter">
					<div>{t('filters.features')}</div>
					<label className="container">{t('filters.balcony')}
						<input checked={balconyFilterValue} onChange={onBalconyFilterChange} type="checkbox" />
						<span className ="checkmark"></span>
					</label>
					<label className="container">{t('filters.petsAllowed')}
						<input checked={petsFilterValue} onChange={onPetsFilterChange} type="checkbox"/>
						<span className ="checkmark"></span>
					</label>
					<label className="container">{t('filters.elevator')}
						<input checked={elevatorFilterValue} onChange={onElevatorFilterChange} type="checkbox"/>
						<span className ="checkmark"></span>
					</label>
				</div>
			</SideDrawerWrapper>
		</>
	);
};

export default AttributesSideDrawer;