import React, { useState } from 'react';
import styled from 'styled-components';
import background from 'assets/images/hero-background.jpg';
import SideDrawer from 'components/SideDrawer';
import FilterButton from './FilterButton';
import { useHistory, useLocation } from 'react-router-dom';
import useSiteConfig from 'features/site-config';
import SearchBox from 'components/SearchBox';
import DisplayModeButton from './DisplayModeButton';
import ImageModeButton from './ImageModeButton';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import SortButton from './SortButton';
import queryString from 'query-string';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import SearchAgentDrawer from 'pages/SearchAgent/SearchAgentDrawer';
import AttributesSideDrawer from 'components/SideDrawer/AttributesSideDrawer';

const Wrapper = styled.div`
	width: 100%;
	height: 424px;
	margin: auto;
	background-image: url(${background});
	background-size: auto;
	position: relative;

	@media ${device.laptop} { 
		background-size: cover;
		height: 578px;
	}

	h1 {
		color: white;
		font-size: 32px;
		text-align: center;
		padding-top: 144px;
		padding-bottom: 24px;
		margin: unset;

		@media ${device.laptop} {
			font-size: 64px;
			padding-top: 206px;
		}
	}

	div.search-container {
		padding-top: 24px;
		display: flex;
		direction: column;
		margin: auto;
	}

	// input {
	// 	width: 414px;
	// 	height: 21px;
	// 	padding: unset;
	// 	border: unset;
	// 	border: 2px solid #384336;
	// 	padding: 13px 24px 12px 24px 
	// }

	button#search {
		width: 211px;
		height: 50px;
		padding: unset;
		border: unset;
		background: #384336;
		font-size: 16px;
		color: white;
	}

	div.controls-container {
		height: 50px;
		background: #D7D9D7;
		box-shadow: 0px 32px 34px rgba(0, 0, 0, 0.133714);
		margin-right: auto;
		margin-left: auto;
		display: flex;
		grid-column-gap: 2px;
		margin-top: 24px;
		width: 100%;
		position: absolute;
		bottom: 0px;
		left: 0; 
  		right: 0; 
  

		@media ${device.laptop} { 
			width: 960px;
			margin-top: 91px;
			height: 100px;
			margin-top: 0px;
		}

		button, > div {
			cursor: pointer;
			color: ${p => p.theme.colors.ascent.primary};
			height: 50px;
			font-size: 17px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: -0.427179px;
			border: unset;
			background-color: white;
			flex: 1;

			&:hover {
				background-color: ${p => p.theme.colors.ascent.primary};
				color: white;	
			}

			@media ${device.laptop} {
				height: 100px;
				font-weight: 600;
			}
			
		}

		.hidden {
			display: none;
		}
	}
`;

const ControlsSection = (props) => {

	const {
		updateSelectedPlace,
		searchQuery,
		setSearchQuery,
		sortOptions,
		selectedSortOption,
		setSelectedSortOption,
		gridViewMode,
		setGridViewMode,
		floorplanImageMode,
		setFloorplanImageMode,
		rentFilterValue,
		setRentFilterValue,
		sizeFilterValue,
		setSizeFilterValue,
		roomsFilterValue,
		setRoomsFilterValue,
		defaultFilterValues,
		balconyFilterValue,
		setBalconyFilterValue,
		petsFilterValue,
		setPetsFilterValue,
		elevatorFilterValue,
		setElevatorFilterValue,
		updateUrl,
		acquisitionFilterValue,
		setAcquisitionFilterValue,
		disableFilter,
		searchAgentParams
	} = props;
	const history = useHistory();
	const config = useSiteConfig();
	const { t } = useTranslation();
	const [filterOpen, setFilterOpen] = useState(false);
	const [searchAgentCreatorOpen, setSearchAgentCreatorOpen] = useState(false);
	const [sortOpen, setSortOpen] = useState(false);

	const toggleFilter = () => {
		setFilterOpen(!filterOpen);
	};

	return (
		<Wrapper>
			{!disableFilter &&
				<SideDrawer
					visible={filterOpen}
					setVisible={setFilterOpen}
					rentFilterValue={rentFilterValue}
					setRentFilterValue={setRentFilterValue}
					sizeFilterValue={sizeFilterValue}
					setSizeFilterValue={setSizeFilterValue}
					roomsFilterValue={roomsFilterValue}
					setRoomsFilterValue={setRoomsFilterValue}
					defaultFilterValues={defaultFilterValues}
					balconyFilterValue={balconyFilterValue}
					setBalconyFilterValue={setBalconyFilterValue}
					petsFilterValue={petsFilterValue}
					setPetsFilterValue={setPetsFilterValue}
					elevatorFilterValue={elevatorFilterValue}
					setElevatorFilterValue={setElevatorFilterValue}
					acquisitionFilterValue={acquisitionFilterValue}
					setAcquisitionFilterValue={setAcquisitionFilterValue}
				/>
			}
			{disableFilter &&
				<AttributesSideDrawer
					visible={filterOpen}
					setVisible={setFilterOpen}
					setBalconyFilterValue={setBalconyFilterValue}
					petsFilterValue={petsFilterValue}
					setPetsFilterValue={setPetsFilterValue}
					elevatorFilterValue={elevatorFilterValue}
					setElevatorFilterValue={setElevatorFilterValue}
				/>
			}
			{searchAgentParams &&
				<SearchAgentDrawer
					visible={searchAgentCreatorOpen}
					setVisible={setSearchAgentCreatorOpen}
					searchAgentParams={searchAgentParams}
				/>
			}
			<h1>{t('home.title')}</h1>
			{setSearchQuery &&
				<SearchBox
					searchQuery={searchQuery}
					setSearchQuery={setSearchQuery}
					updateSelectedPlace={updateSelectedPlace}
					updateUrl={updateUrl}
				/>
			}
			{searchAgentParams &&
				<SearchAgentBox
					searchAgentParams={searchAgentParams}
					openSearchCreator={() => setSearchAgentCreatorOpen(true)}
				/>
			}
			<div className="controls-container">
				<FilterButton
					onClick={toggleFilter}
				/>
				<SortButton
					visible={sortOpen}
					setVisible={setSortOpen}
					options={sortOptions}
					value={selectedSortOption}
					setValue={setSelectedSortOption}
				/>
				<DisplayModeButton
					gridViewMode={gridViewMode}
					setGridViewMode={setGridViewMode}
				/>
				<ImageModeButton
					floorplanImageMode={floorplanImageMode}
					setFloorplanImageMode={setFloorplanImageMode}
				/>
			</div>
		</Wrapper>
	);
};

const StyledSearchAgentBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 100%;

	.active-params {
		display: flex;
		direction: column;
		justify-content: center;
		align-items: center;
		padding: 24px;
		max-width: calc(100% - 48px);
	}

	@media ${device.laptop} { 
		padding: 0px 24px 84px 24px;
	}

	.params-box {
		font-size: 16px;
		height: 21px;
		padding: unset;
		border: unset;
		border: 2px solid #384336;
		padding: 13px 24px 12px 24px; 
		background-color: white;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 100%;
	}

	a.change-params {
		height: 50px;
		padding: unset;
		padding: 0px 24px;
		border: unset;
		background: #384336;
		font-size: 16px;
		color: white;
		cursor: pointer;
		display: flex;
		justify-items: center;
		align-items: center;
	}

	button.create-agent {
		height: 50px;
		padding: unset;
		padding: 0px 24px;
		border: unset;
		background: #384336;
		font-size: 16px;
		color: white;
		cursor: pointer;
		display: flex;
		justify-items: center;
		align-items: center;
	}
`;

const SearchAgentBox = ({ searchAgentParams, openSearchCreator }) => {

	const location = useLocation();
	const history = useHistory();

	const navigateToEditParams = () => {
		const updatedParams = {
			minRent: searchAgentParams.minRent,
			maxRent: searchAgentParams.maxRent,
			minRooms: searchAgentParams.minRooms,
			maxRooms: searchAgentParams.maxRooms,
			regions: searchAgentParams.regions.join(','),
		};
		history.push({
			pathname: `/search`,
			search: queryString.stringify(updatedParams),
		});
	};

	return (
		<>
			<StyledSearchAgentBox>
				<div className="active-params">
					<p className="params-box">{`${searchAgentParams.regions.join(', ')}, ${searchAgentParams.minRent} - ${searchAgentParams.maxRent}, ${searchAgentParams.minRooms} - ${searchAgentParams.maxRooms} værelser`}</p>
					<a className="change-params" onClick={navigateToEditParams}>
						<FilterIcon stroke="white" />
					</a>
				</div>
				<button className="create-agent" onClick={openSearchCreator}>
					Opret Søgeagent
				</button>
			</StyledSearchAgentBox>
		</>
	);
};

export default ControlsSection;