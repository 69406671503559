import React from 'react';
import styled from 'styled-components';
import {ReactComponent as FloorplanIcon} from 'assets/icons/floorplan.svg';
import {ReactComponent as ImageIcon} from 'assets/icons/image.svg';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const ImageModeButtonWrapper = styled.div`

	padding: unset;
	display: none;

	button {
		cursor: pointer;
		position: relative;
		width: 238px;
		height: 100px;
		font-size: 17px;
		font-weight: 600;
		line-height: 22px;
		letter-spacing: -0.427179px;
		border: unset;
		background-color: white;

		&:hover {
			background-color: ${p => p.theme.colors.ascent.primary};
			color: white;	
		}
	}

	@media ${device.laptop} { 
		display: block;
	}
`;

const ImageViewButton = styled.div`
	display: flex;
	flex-direction: row;
    justify-content: center;
    align-items: center;
	height: 100%;

	svg {
		stroke: ${p => p.theme.colors.ascent.primary};
		align-self: center;
		margin-right: 12px;
		max-height: 25px;
	}

	&:hover {
		svg {
			fill: ${p => p.theme.colors.ascent.primary};
			stroke: ${p => p.theme.colors.white.primary};
			rect {
				stroke: ${p => p.theme.colors.white.primary};
			}
		}
	}
`;

const FloorplanViewButton = styled.div`
	display: flex;
	flex-direction: row;
    justify-content: center;
    align-items: center;
	height: 100%;

	svg {
		stroke: ${p => p.theme.colors.ascent.primary};
		align-self: center;
		margin-right: 12px;
		max-height: 45px;
	}

	&:hover {
		svg {
			stroke: ${p => p.theme.colors.white.primary};
			fill: ${p => p.theme.colors.ascent.primary};

		}
	}
`;

const ImageModeButton = (props) => {

	const { floorplanImageMode, setFloorplanImageMode } = props;
	const { t } = useTranslation();
	
	const handleClick = () => {
		setFloorplanImageMode(!floorplanImageMode);
	};

	let visibleButton;
	if (floorplanImageMode) {
		visibleButton = <ImageViewButton><ImageIcon/><div>{t('tenancies.displayOptions.image')}</div></ImageViewButton>;
	} else {
		visibleButton = <FloorplanViewButton><FloorplanIcon/><div>{t('tenancies.displayOptions.floorplan')}</div></FloorplanViewButton>;
	}

	return (
		<ImageModeButtonWrapper onClick={handleClick}>
			{visibleButton}
		</ImageModeButtonWrapper>
	);
};

ImageModeButton.propTypes = {
	floorplanImageMode: PropTypes.bool.isRequired,
	setFloorplanImageMode: PropTypes.func.isRequired,
};

export default ImageModeButton;