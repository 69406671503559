import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import formatPrice from 'helpers/formatPrice';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { ReactComponent as Map } from 'assets/images/dk-map.svg';
import { device } from 'helpers/device';
import Regions from './regions.js';
import useTenancies from 'features/tenancies';
import { filterData } from './helpers.js';


const PageWrapper = styled.div`
	background: white;
	width: 100%;
    max-width: 1120px;
    margin: auto;
	margin-top: 100px;
	padding: 50px 0px 50px 0px;

	.title {
		font-size: 32px;
		color: ${p => p.theme.colors.ascent.primary};
		font-weight: 600;
		text-align: center;
		margin-top: 40px;
		margin-bottom: 40px;
	}

	.step-button {
		background-color: ${p => p.theme.colors.ascent.primary};
		color: white;
		font-size: 18px;
		font-weight: 500;
		font-family: inherit;
		cursor: pointer;
		outline: inherit;
		border: 0;
		padding: 10px 20px;

		&:disabled {
			background-color: ${p => p.theme.colors.off.primary};
			cursor: not-allowed;
		}
	}

	.stage-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.stage-bar-container {
		position: relative;
		margin: auto;
		max-width: 400px;

		.stage-bar {
			display: flex;
			justify-content: space-between;

			.stage-container {
				width: 50px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: ${p => p.theme.colors.ascent.primary};
				font-size: 22px;
				font-weight: 500;
				color: white;
				z-index: 2;

				&.active {
					background-color: ${p => p.theme.colors.ascent.secondary}
				}
			}
		}

		.stage-bar-line {
			width: 100%;
			height: 6px;
			background-color: ${p => p.theme.colors.ascent.primary};
			position: absolute;
			top: 23px;
			z-index: 1;
		}
	}

	.slider-wrapper {
		padding: 12px 0px;
		height: 24px;
		width: 100%;
		max-width: 350px;
	}

	.slider-thumb {
		color: ${p => p.theme.colors.white.primary};
		background: ${p => p.theme.colors.ascent.primary};
		border-radius: 12px;
		padding: 12px;
	}
	.slider-track {
		top: 22px;
		height: 4px;
		background: ${p => p.theme.colors.off.primary};
	}

	.slider-track-1 {
		background: ${p => p.theme.colors.ascent.secondary};
	}

	.filter-value {
		text-align: center;
		margin-bottom: 50px;
	}

	.room-buttons {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 20px;
		max-width: 400px;
		margin-bottom: 50px;

		.room-button {
			width: 100px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: ${p => p.theme.colors.ascent.primary};
			font-size: 22px;
			font-weight: 500;
			color: white;
			z-index: 2;
			cursor: pointer;

			&.active {
				background-color: ${p => p.theme.colors.ascent.secondary}
			}
		}
	}

	.map-container {
		width: 100%;
		height: auto;
		margin: auto;
		margin-bottom: 50px;

		@media ${device.laptop} {
			width: 800px;
			height: 800px;
		}
	}
`;

const SearchAgentPage = () => {

	const location = useLocation();
	const history = useHistory();
	const {
		isTenanciesLoading,
		tenanciesData,
		loadTenancies
	} = useTenancies();

	const queryParams = queryString.parse(location.search);

	const [activeStage, setActiveStage] = React.useState(1);
	const [rent, setRent] = React.useState(() => {
		const minRent = queryParams.minRent ? Number(queryParams.minRent) : 0;
		const maxRent = queryParams.maxRent ? Number(queryParams.maxRent) : 30000;
		return [minRent, maxRent];
	});
	const [rooms, setRooms] = React.useState(() => {
		const minRooms = queryParams.minRooms ? Number(queryParams.minRooms) : 1;
		const maxRooms = queryParams.maxRooms ? Number(queryParams.maxRooms) : 8;
		return [minRooms, maxRooms];
	});
	const [regions, setRegions] = React.useState(() => {
		return queryParams.regions ? queryParams.regions.split(',').map(String) : [];
	});

	const tenanciesAvailableRegion = filterData(tenanciesData?.items ?? [], {
		regions
	});

	const tenanciesAvailablePrice = filterData(tenanciesData?.items ?? [], {
		regions,
		minRent: rent[0],
		maxRent: rent[1]
	});

	const tenanciesAvailableRooms = filterData(tenanciesData?.items ?? [], {
		minRent: rent[0],
		maxRent: rent[1],
		minRooms: rooms[0],
		maxRooms: rooms[1],
		regions
	});

	const updateUrlParams = (newRent, newRooms) => {
		const updatedParams = {
			minRent: newRent[0],
			maxRent: newRent[1],
			minRooms: newRooms[0],
			maxRooms: newRooms[1],
			regions: regions.join(','),
		};
		history.push({
			pathname: `/search/results`,
			search: queryString.stringify(updatedParams),
		});
	};

	const handleSearch = () => {
		updateUrlParams(rent, rooms);
	};

	useEffect(() => {
		loadTenancies();
	}, []);

	return (
		<PageWrapper>
			<StageBar activeStage={activeStage} />
			{activeStage === 1 &&
				<MapStage
					onContinue={() => setActiveStage(2)}
					value={regions}
					setValue={setRegions}
					tenanciesAvailable={tenanciesAvailableRegion}
				/>
			}
			{activeStage === 2 &&
				<RentStage
					onContinue={() => setActiveStage(3)}
					value={rent}
					setValue={setRent}
					tenanciesAvailable={tenanciesAvailablePrice}
					defaultMinValue={tenanciesAvailableRegion?.reduce((min, p) => p.monthlyRent.value < min ? p.monthlyRent.value : min, tenanciesAvailableRegion[0]?.monthlyRent.value) ?? 0}
					defaultMaxValue={tenanciesAvailableRegion?.reduce((max, p) => p.monthlyRent.value > max ? p.monthlyRent.value : max, tenanciesAvailableRegion[0]?.monthlyRent.value) ?? 30000}
				/>
			}
			{activeStage === 3 &&
				<RoomsStage
					onContinue={() => handleSearch()}
					value={rooms}
					setValue={setRooms}
					tenanciesAvailable={tenanciesAvailableRooms}
					defaultMinValue={tenanciesAvailablePrice?.reduce((min, p) => p.rooms < min ? p.rooms : min, tenanciesAvailablePrice[0]?.rooms) ?? 1}
					defaultMaxValue={tenanciesAvailablePrice?.reduce((max, p) => p.rooms > max ? p.rooms : max, tenanciesAvailablePrice[0]?.rooms) ?? 8}
				/>
			}
		</PageWrapper>
	);
};

const MapStage = ({ onContinue, value, setValue, tenanciesAvailable }) => {

	useEffect(() => {
		for (let region of value) {
			const element = document.getElementById(region);
			if (element) {
				element.classList.add('active');
			}
		}
	}, []);

	const getTarget = (e) => {
		let target = null;
		if (e.target?.tagName === 'g') {
			if (e.target.parentElement.tagName === 'g') {
				target = e.target.parentElement;
			} else {
				target = e.currentTarget;
			}
		}

		if (e.target?.tagName === 'path') {
			const closestG = e.target.closest('g');
			if (closestG.parentElement.tagName === 'g') {
				target = closestG.parentElement;
			} else {
				target = closestG;
			}
		}

		return target;
	};

	const handleClick = (e) => {
		const target = getTarget(e);
		const clickedId = target?.id;

		if (!target)
			return;

		if (value.includes(clickedId)) {
			setValue(value.filter(item => item !== clickedId));
			target.classList.remove('active');
		} else {
			setValue([...value, clickedId]);
			target.classList.add('active');
		}
	};

	const handleHover = (e) => {
		const target = getTarget(e);
		if (!target)
			return;

		const clickedId = target.id;

		const label = Regions.find(region => region.name === clickedId)?.label;
	};

	return (
		<div className="stage-container">
			<p className="title">Hvor henne vil du bo?</p>
			<p>Tryk på de områder du kunne tænke dig at bo i.</p>
			<div className="map-container">
				<Map
					width="100%"
					height="100%"
					preserveAspectRatio="xMidYMid meet"
					onClick={(e) => handleClick(e)}
					onMouseOver={(e) => handleHover(e)}
				/>
			</div>
			<button
				className="step-button"
				onClick={onContinue}
				disabled={value.length === 0}
			>
				Fortsæt {value.length > 0 && `(${tenanciesAvailable?.length} lejemål tilgængelige)`}
			</button>
		</div>
	);
};

const RentStage = ({ onContinue, value, setValue, tenanciesAvailable, defaultMinValue, defaultMaxValue }) => {
	const { t } = useTranslation();

	useEffect(() => {
		setValue([defaultMinValue, defaultMaxValue]);
	}, [defaultMinValue, defaultMaxValue]);

	return (
		<div className="stage-container">
			<p className="title">Hvad skal det koste?</p>
			<ReactSlider
				className="slider-wrapper"
				thumbClassName="slider-thumb"
				trackClassName="slider-track"
				min={defaultMinValue}
				max={defaultMaxValue}
				minDistance={1000}
				step={1000}
				value={value}
				onChange={setValue}
				ariaLabel={['Lower thumb', 'Upper thumb']}
				ariaValuetext={state => `Thumb value ${state.valueNow}`}
				renderThumb={(props, state) => <div {...props}></div>}
				pearling
			/>
			<div className="filter-value">{formatPrice(value[0])} {`${t('filters.to')}`} {value[1] === defaultMaxValue ? `${defaultMaxValue.toLocaleString('da-DK')}+ kr.` : formatPrice(value[1])}</div>
			<button
				className="step-button"
				onClick={onContinue}
			>
				Fortsæt {`(${tenanciesAvailable?.length} lejemål tilgængelige)`}
			</button>
		</div>
	);
};

const RoomsStage = ({ onContinue, value, setValue, tenanciesAvailable, defaultMinValue, defaultMaxValue }) => {

	const { t } = useTranslation();

	useEffect(() => {
		setValue([defaultMinValue, defaultMaxValue]);
	}, [defaultMinValue, defaultMaxValue]);

	return (
		<div className="stage-container">
			<p className="title">Hvor mange værelser?</p>
			<ReactSlider
				className="slider-wrapper"
				thumbClassName="slider-thumb"
				trackClassName="slider-track"
				min={defaultMinValue}
				max={defaultMaxValue}
				minDistance={0}
				step={1}
				value={value}
				onChange={setValue}
				ariaLabel={['Lower thumb', 'Upper thumb']}
				ariaValuetext={state => `Thumb value ${state.valueNow}`}
				renderThumb={(props, state) => <div {...props}></div>}
				pearling
			/>
			<div className="filter-value">{value[0]} - {value[1]}</div>
			<button
				className="step-button"
				onClick={onContinue}
			>
				Se Boliger {`(${tenanciesAvailable?.length} lejemål tilgængelige)`}
			</button>
		</div>
	);
};

const StageBar = ({ activeStage }) => {
	return (
		<div className="stage-bar-container">
			<div className="stage-bar">
				<StageButton stage="1" isActive={activeStage === 1} />
				<StageButton stage="2" isActive={activeStage === 2} />
				<StageButton stage="3" isActive={activeStage === 3} />
			</div>
			<div className="stage-bar-line"></div>
		</div>
	);
};

const StageButton = ({ stage, isActive }) => {
	return (
		<div className={`stage-container ${isActive ? 'active' : ''}`}>
			{stage}
		</div>
	);
};

export default SearchAgentPage;