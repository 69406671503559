import React, { useState } from 'react';
import styled from 'styled-components';
import background from 'assets/images/hero-background.jpg';
import SearchBox from 'components/SearchBox';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';
import SearchAgent from 'components/SearchAgent/SearchAgent';

const HeroWrapper = styled.div`
	height: 100vh;
	width: 100%;
	margin: auto;
	background-image: url(${background});
	background-size: auto;
	background-position: center;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	@media ${device.laptop} { 
		background-size: cover;
	}

	a.search-redirect {
		margin-top: 85px;
		color: white;
		font-weight: 500;
		font-size: 15px;
		cursor: pointer;
		span {
			font-weight: 600;
		}
	}
`;

const Title = styled.h1`
	color: white;
	font-size: 34px;
	text-align: center;
	padding-bottom: 24px;
	margin: unset;
	@media ${device.laptop} { 
		font-size: 64px;
	}
`;

const HeroSection = () => {
	const { t } = useTranslation();

	let history = useHistory();
	const [searchQuery, setSearchQuery] = useState('');

	const updateUrl = (pathname) => {
		window.location.href = pathname;
		history.push({ 
			pathname: pathname,
		});
	};
	
	return (
		<HeroWrapper>
			<Title>{t('home.title')}</Title>
			<SearchBox 
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
				updateUrl={updateUrl}
			/>
			<SearchAgent />
		</HeroWrapper>
	);
};

HeroSection.propTypes = {
	background: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
};

export default HeroSection;