import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';

const SearchAgentWrapper = styled.div`

	width: 100%;
	max-width: 320px;

	@media ${device.laptop} { 
		max-width: 566px;
		width: 100%;
	}

	button {
		height: 50px;
		padding: unset;
		padding: 0px 24px;
		border: unset;
		background: #384336;
		font-size: 16px;
		color: white;
		cursor: pointer;
		width: 100%;
	}
`;

const SearchAgent = (props) => {
	const { t } = useTranslation();

	return (
		<SearchAgentWrapper>
			<a href="/search"><button>{t('searchAgent.buttonLabel')}</button></a>
		</SearchAgentWrapper>
	);
};

export default SearchAgent;