import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from 'pages/Home';
import Tenancy from 'pages/Tenancy';
import Tenancies from 'pages/Tenancies';
import Contact from './Contact';
import AboutUs from './AboutUs';
import Telegrafkollegiet from './Properties/Telegrafkollgiet';
import Smedetoften from './Properties/Smedetoften';
import Skovstykket from './Properties/Skovstykket';
import Labyrinthen from './Properties/Labyrinthen';
import Baunebakken from './Properties/Baunebakken';
import Lysningen from './Properties/Lysningen/Lysningen';
import Soesvinget from './Properties/Soesvinget';
import Trylleby from './Properties/Trylleby';
import PrivacyPolicy from './PrivacyPolicy';
import KGV from './Properties/KGV';
import KGV2 from './Properties/KGV2/KGV2';
import SearchAgentResultsPage from './SearchAgent/SearchAgentResults';
import SearchAgentPage from './SearchAgent/SearchAgent';
 
const PagesRouter = () => {

	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route path={`/${process.env.REACT_APP_ROUTER_TENANCY}/*+:id`} component={Tenancy} />
			<Route path={`/${process.env.REACT_APP_ROUTER_TENANCY}/:id`} component={Tenancy} />
			<Route path={`/${process.env.REACT_APP_ROUTER_TENANCIES}`} component={Tenancies} />
			<Route path={'/ejendomme/telegrafkollegiet'} component={Telegrafkollegiet} />
			<Route path={'/ejendomme/smedetoften'} component={Smedetoften} />
			<Route path={'/ejendomme/skovstykket'} component={Skovstykket} />
			<Route path={'/ejendomme/labyrinthen'} component={Labyrinthen} />
			<Route path={'/ejendomme/baunebakken'} component={Baunebakken} />
			<Route path={'/ejendomme/lysningen'} component={Lysningen} />
			<Route path={'/ejendomme/soesvinget'} component={Soesvinget} />
			<Route path={'/ejendomme/trylleby'} component={Trylleby} />
			<Route path={'/ejendomme/kgv'} component={KGV2} />
			{/* <Route path={'/ejendomme/kgv2'} component={KGV2} /> */}
			<Route path={'/privacy-policy'} component={PrivacyPolicy} />
			<Route path='/kontakt' component={Contact} />
			<Route path='/om-os' component={AboutUs} />
			<Route path='/search/results' component={SearchAgentResultsPage} />
			<Route path='/search' component={SearchAgentPage} />
		</Switch>
	);
};

export default PagesRouter;