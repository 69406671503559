import React, { useState } from 'react';
import styled from 'styled-components';
import useKeypress from 'components/useKeyPress';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import da from 'date-fns/locale/da';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import formatPrice from 'helpers/formatPrice';
import axios from 'services/api/axios';
import Regions from './regions';
registerLocale('da', da);


const SideDrawerWrapper = styled.nav`
	min-width: 280px;
	height: 100vh;
	z-index: 1000;
	position: fixed;
	background-color: white;
	transform: translateX(-100%);
	transition: transform 0.3s ease-out;
	padding: 24px;
    box-sizing: border-box;
	overflow-y: scroll;

	@media ${device.laptop} { 
		width: 400px;
		padding: 24px 48px;
	}


	&.visible {
		transform: translateX(0);
	}

	div.title {
		font-size: 18px;
		padding: 36px 24px;
		text-align: center;

		svg {
			padding-right: 16px;
		}
	}

	.input-wrapper {
		padding: 12px 0px;
		display: block;

		input, textarea, select {
			padding: 4px 0px 8px;
			width: 100%;
			border: unset;
			border-bottom: 1px solid ${p => p.theme.colors.ascent.primary};
			font-size: 16px;
			color: ${p => p.theme.colors.ascent.primary};
			background: none; /* Optional: Ensures consistent background styling */
			appearance: none; /* Optional: Removes default select styling */
		}

		input[type=textarea] {
			height: 200px;
		}
	
		label {
			font-size: 14px;
			display: block;
			color: ${p => p.theme.colors.text.secondary};
		}
	}

	.checkbox {
		/* The container */
		.container {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-top: 8px;
		margin-bottom: 12px;
		cursor: pointer;
		
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		}

		/* Hide the browser's default checkbox */
		.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		}

		/* Create a custom checkbox */
		.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		}

		/* On mouse-over, add a grey background color */
		.container:hover input ~ .checkmark {
		background-color: #ccc;
		}

		/* When the checkbox is checked, add a blue background */
		.container input:checked ~ .checkmark {
		background-color: ${p => p.theme.colors.ascent.primary};
		}

		/* Create the checkmark/indicator (hidden when not checked) */
		.checkmark:after {
		content: "";
		position: absolute;
		display: none;
		}

		/* Show the checkmark when checked */
		.container input:checked ~ .checkmark:after {
		display: block;
		}

		/* Style the checkmark/indicator */
		.container .checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		}

		span {
			position: relative;
			top: 4px;
		}
	}

	button.create-agent {
		height: 50px;
		padding: 0px 24px;
		border: unset;
		background: #384336;
		font-size: 16px;
		color: white;
		cursor: pointer;
		display: flex;
		justify-content: center; /* Center horizontally */
		align-items: center; /* Center vertically */
		text-align: center;
		width: 100%;
		margin-top: 24px;
	}

	.params {
		display: flex;
		flex-direction: column;
		gap: 8px;
		border-top: 1px solid #38443633;
		border-bottom: 1px solid #38443633;
		padding: 16px 0px;
		margin-bottom: 18px;

		.param {
			display: flex;
			flex-direction: column;
			gap: 4px;
			justify-content: center;
			align-items: center;

			p {
				text-align: center;

				&.param-name {
					font-weight: 500;
				}
			}
		}
	}

	.loading-container {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #0000006b;
		color: white;
		font-size: 24px;
		z-index: 1000;
		left: 0;
		top: 0;
		display: flex;
		flex-direction: column;

		p {
			font-size: 16px;
			max-width: 300px;
			text-align: center;
		}

		button.create-agent {
			max-width: 200px;
		}
	}
`;

const Backdrop = styled.div`
	width: 100vw;
	height: 100vh;
	display: none;
	position: fixed;
	background-color: #0000006b;
	z-index: 900;

	&.visible {
		display: block;
	}
`;

const SearchAgentDrawer = ({ visible, setVisible, searchAgentParams }) => {
	const { t } = useTranslation();
	const [name, setName] = useState(null);
	const [mail, setMail] = useState(null);
	const [phone, setPhone] = useState(null);
	const [subscriptionType, setSubscriptionType] = useState("weekly");
	const [contactable, setContactable] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDone, setIsDone] = useState(false);

	const handleBackdropClick = () => {
		setVisible(false);
		setIsLoading(false);
		setIsDone(false);
	};

	const createAgent = async () => {
		setIsLoading(true);

		const regions = searchAgentParams.regions ?? [];
		const zipCodes = Regions.filter(region => regions.includes(region.name)).flatMap(region => region.postalCodes).map(x => x.toString());

		try {
			const result = await axios.post(`/interest-list/tenancy-agents`, {
				name,
				email: mail,
				phoneNumber: phone,
				emailSubscription: subscriptionType,
				contactable,
				criteria: {
					priceMin: searchAgentParams.minRent,
					priceMax: searchAgentParams.maxRent,
					roomsMin: searchAgentParams.minRooms,
					roomsMax: searchAgentParams.maxRooms,
					zipCodes
				}
			});
			setIsLoading(false);
			setIsDone(true);
			return result;
		}
		catch (err) {
			setIsLoading(false);
			return null;
		}
	};

	useKeypress('Escape', () => {
		if (visible) {
			setVisible(false);
			setIsLoading(false);
			setIsDone(false);
		}
	});

	return (
		<>
			<Backdrop onClick={handleBackdropClick} className={visible ? 'visible' : ''} />
			<SideDrawerWrapper className={visible ? 'visible' : ''}>
				{isLoading && <div className="loading-container">Loading...</div>}
				{isDone &&
					<div className="loading-container">
						<p>Boligagent oprettet. Du vil modtage en e-mailbekræftelse inden længe.</p>
						<button className="create-agent" onClick={() => handleBackdropClick()}>Luk</button>
					</div>
				}
				<div className="title">Opret Søgeagent</div>
				<div className="params">
					<div className="param">
						<p className="param-name">Områder</p>
						<p className="param-value">{searchAgentParams.regions.join(', ')}</p>
					</div>
					<div className="param">
						<p className="param-name">Husleje</p>
						<p className="param-value">{formatPrice(searchAgentParams.minRent)} - {formatPrice(searchAgentParams.maxRent)}</p>
					</div>
					<div className="param">
						<p className="param-name">Værelser</p>
						<p className="param-value">{searchAgentParams.minRooms} - {searchAgentParams.maxRooms}</p>
					</div>
				</div>
				<div>
					<div className="input-wrapper">
						<label htmlFor="name">{`${t('contact.name')}`}</label>
						<input onChange={e => setName(e.target.value)} value={name} id="name" type="text" />
					</div>
					<div className="input-wrapper">
						<label htmlFor="email">{`${t('contact.email')}`}</label>
						<input onChange={e => setMail(e.target.value)} value={mail} id="email" type="text" />
					</div>
					<div className="input-wrapper">
						<label htmlFor="phone">{`${t('contact.phone')}`}</label>
						<input onChange={e => setPhone(e.target.value)} value={phone} id="phone" type="text" />
					</div>
					<div className="input-wrapper">
						<label htmlFor="subscription-type">Hvor ofte vil du modtage en e-mail?</label>
						<select name="subscription-type" value={subscriptionType} onChange={e => setSubscriptionType(e.target.value)}>
							{/* <option value="none">None</option> */}
							<option value="daily">Dagligt</option>
							<option value="weekly">Ugentligt</option>
						</select>
					</div>
					<div className="input-wrapper checkbox">
						<label className="container">
							<span>Må vi kontakte dig direkte, hvis vi har lejemål vi tænker du kunne finde interessant?</span>
							<input checked={contactable} onChange={e => setContactable(!contactable)} type="checkbox" />
							<span className="checkmark"></span>
						</label>
					</div>
				</div>
				<button className="create-agent" onClick={createAgent}>Opret Søgeagent</button>
			</SideDrawerWrapper>
		</>
	);
};

export default SearchAgentDrawer;