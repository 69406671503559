import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import ControlsSection from 'components/ControlsSection/ControlsSection';
import TenanciesSection from 'components/TenanciesSection/TenanciesSection';
import { useTranslation } from 'react-i18next';
import useTenancies from 'features/tenancies';
import { filterData } from './helpers';

const SearchAgentResultsPage = () => {

	const { t } = useTranslation();
	const {
		isTenanciesLoading,
		tenanciesData,
		loadTenancies
	} = useTenancies();
	const queryParams = queryString.parse(location.search);

	const sortOptions = [
		{ value: 'price', label: t('tenancies.sortOptions.cheapest') },
		{ value: 'newest', label: t('tenancies.sortOptions.newest') },
		{ value: 'available', label: t('tenancies.sortOptions.earliestAvailable') }
	];

	const minRent = queryParams.minRent ? Number(queryParams.minRent) : 0;
	const maxRent = queryParams.maxRent ? Number(queryParams.maxRent) : 30000;
	const minRooms = queryParams.minRooms ? Number(queryParams.minRooms) : 1;
	const maxRooms = queryParams.maxRooms ? Number(queryParams.maxRooms) : 8;
	const regions = queryParams.regions ? queryParams.regions.split(',').map(String) : [];

	const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);
	const [gridViewMode, setGridViewMode] = useState(true);
	const [floorplanImageMode, setFloorplanImageMode] = useState(false);

	const [balconyFilterValue, setBalconyFilterValue] = useState(false);
	const [petsFilterValue, setPetsFilterValue] = useState(false);
	const [elevatorFilterValue, setElevatorFilterValue] = useState(false);

	const sortData = (data) => {
		if (!data) {
			return [];
		}

		let sorted;
		switch (selectedSortOption.value) {
			case 'price':
				sorted = data?.sort((a, b) => {
					return a.monthlyRent.value - b.monthlyRent.value;
				}) ?? [];
				break;
			case 'size':
				sorted = data?.sort((a, b) => {
					return a.size.value - b.size.value;
				}) ?? [];
				break;
			case 'newest':
				sorted = data?.sort((a, b) => {
					const d1 = new Date(a.publishedAt);
					const d2 = new Date(b.publishedAt);
					return d2.getTime() - d1.getTime();
				}) ?? [];
				break;
			case 'available':
				sorted = data?.sort((a, b) => {
					const d1 = new Date(a.availableFrom);
					const d2 = new Date(b.availableFrom);
					return d1.getTime() - d2.getTime();
				}) ?? [];
				break;
		}

		return sorted;
	};

	const sortedData = filterData(sortData(tenanciesData?.items ?? []), {
		minRent,
		maxRent,
		minRooms,
		maxRooms,
		regions,
		balcony: balconyFilterValue,
		pets: petsFilterValue,
		elevator: elevatorFilterValue
	});
	const isLoading = !sortedData || isTenanciesLoading;

	useEffect(() => {
		loadTenancies();
	}, []);

	return (
		<div>
			<ControlsSection
				sortOptions={sortOptions}
				selectedSortOption={selectedSortOption}
				setSelectedSortOption={setSelectedSortOption}
				gridViewMode={gridViewMode}
				setGridViewMode={setGridViewMode}
				floorplanImageMode={floorplanImageMode}
				setFloorplanImageMode={setFloorplanImageMode}
				disableFilter={true}
				searchAgentParams={{
					minRent,
					maxRent,
					minRooms,
					maxRooms,
					regions
				}}
				balconyFilterValue={balconyFilterValue}
				setBalconyFilterValue={setBalconyFilterValue}
				petsFilterValue={petsFilterValue}
				setPetsFilterValue={setPetsFilterValue}
				elevatorFilterValue={elevatorFilterValue}
				setElevatorFilterValue={setElevatorFilterValue}
			/>
			<TenanciesSection
				loading={isLoading}
				filteredTenancies={sortedData ?? []}
				allTenancies={tenanciesData?.items}
				floorplanImageMode={floorplanImageMode}
				gridViewMode={gridViewMode}
				selectedPlace={undefined}
			/>
		</div>
	);
};

export default SearchAgentResultsPage;