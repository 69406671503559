import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as MenuIconGreen } from "assets/icons/menu-green.svg";
import { ReactComponent as MenuIconWhite } from "assets/icons/menu-white.svg";
import { device } from "helpers/device";
import MobileNavigation from "components/MobileNavigation";
import { ReactComponent as LogoNormal } from "assets/images/norse-logo-green.svg";
import { ReactComponent as LogoInverse } from "assets/images/norse-logo-white.svg";

const Wrapper = styled.header`
	width: 100%;
	background: transparent;
	z-index: 100;
	svg {
		height: 100px;
	}
	&.white {
		background-color: white;
	}
	&.transparent {
		background-color: transparent;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
`;

const Container = styled.header`
	max-width: 1120px;
	margin: auto;
	padding: 16px 16px 16px 16px;
	display: flex;
	align-items: center;

	img {
		height: 70px;
		cursor: pointer;
	}

	@media ${device.laptop} {
		padding: 16px 0px 16px 16px;
	}
`;

const Logo = styled.a`
	margin-right: auto;
`;

const Navigation = styled.header`
	display: none;

	a {
		color: white;
		padding: 0px 24px;
		font-size: 20px;
		font-weight: 500;
		cursor: pointer;
	}

	&.inversed {
		a {
			color: ${(p) => p.theme.colors.text.primary};
		}
		span {
			color: ${(p) => p.theme.colors.text.primary};
		}
	}

	@media ${device.laptop} {
		display: flex;
	}
	.language {
		display: flex;
		align-items: center;
		font-size: 22px;
		padding-right: 8px;
		color: #fff;

		.active {
			font-weight: 900;
		}
		.active,
		.inactive {
			cursor: pointer;
		}
	}
	.seperator {
		padding-left: 4px;
		padding-right: 4px;
	}
`;

const MobileNavigationWrapper = styled.header`
	display: flex;

	a {
		color: white;
		padding: 0px 24px;
		font-size: 20px;
		font-weight: 500;
		cursor: pointer;
	}

	svg {
		height: 50px;
		width: 50px;
	}

	&.inversed {
		a {
			color: ${(p) => p.theme.colors.text.primary};
		}
		span {
			color: ${(p) => p.theme.colors.text.primary};
		}
	}

	.language {
		display: flex;
		align-items: center;
		font-size: 22px;
		padding-right: 8px;
		color: #fff;

		.active {
			font-weight: 900;
		}
		.active,
		.inactive {
			cursor: pointer;
		}
	}
	.seperator {
		padding-left: 4px;
		padding-right: 4px;
	}

	@media ${device.laptop} {
		max-width: 800px;
		display: none;
	}
`;

const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const { pathname } = useLocation();
	const inversedPaths = [
		"/bolig/",
		"/boliger/",
		"/kontakt",
		"/om-os",
		"/privacy-policy",
		"/ejendomme",
	];
	const isInversedHeader = inversedPaths.some((prefix) =>
		pathname.startsWith(prefix) || (pathname.startsWith("/search") && !pathname.startsWith("/search/results"))
	);
	const transparentPaths = ["/", "/boliger", '/search/results'];
	const isTransparentHeader = transparentPaths.includes(location.pathname);

	const { t, i18n } = useTranslation();

	const handleClick = () => {
		setMenuOpen(true);
	};

	const handleLanaguageClick = (language) => {
		i18n.changeLanguage(language);
	};

	return (
		<Wrapper
			className={`${isInversedHeader ? "white" : ""} ${
				isTransparentHeader ? "transparent" : ""
			}`}
		>
			<Container>
				<Logo href='/'>
					{!isInversedHeader ? <LogoInverse /> : <LogoNormal />}
				</Logo>
				<Navigation
					className={isInversedHeader ? "inversed" : "regular"}
				>
					<div className='language'>
						<span
							onClick={() => handleLanaguageClick("da")}
							className={
								i18n.language === "da" ? "active" : "inactive"
							}
						>
							DA
						</span>
						<span className='seperator'>/</span>
						<span
							onClick={() => handleLanaguageClick("en")}
							className={
								i18n.language === "en" ? "active" : "inactive"
							}
						>
							EN
						</span>
					</div>
					<a href='/boliger'>{t("header.findTenancy")}</a>
					<a href='/om-os'>{t("header.aboutUs")}</a>
					<a href='/kontakt'>{t("header.contact")}</a>
				</Navigation>
				<MobileNavigationWrapper>
					<div className='language'>
						<span
							onClick={() => handleLanaguageClick("da")}
							className={
								i18n.language === "da" ? "active" : "inactive"
							}
						>
							DA
						</span>
						<span className='seperator'>/</span>
						<span
							onClick={() => handleLanaguageClick("en")}
							className={
								i18n.language === "en" ? "active" : "inactive"
							}
						>
							EN
						</span>
					</div>
					{isInversedHeader ? (
						<MenuIconGreen onClick={handleClick} />
					) : (
						<MenuIconWhite onClick={handleClick} />
					)}
					<MobileNavigation
						visible={menuOpen}
						setVisible={setMenuOpen}
					/>
				</MobileNavigationWrapper>
			</Container>
		</Wrapper>
	);
};

export default Header;
