import Regions from './regions';

export const filterData = (data, params) => {
	if (!data) {
		return [];
	}

	if (params.minRent && params.maxRent) {
		data = data.filter(item => {
			return (item.monthlyRent.value >= params.minRent && item.monthlyRent.value <= params.maxRent);
		});
	}

	if (params.regions) {
		const zipCodes = Regions.filter(region => params.regions.includes(region.name)).flatMap(region => region.postalCodes).map(x => x.toString());
		data = data.filter(item => {
			return zipCodes.includes(item.address.zipCode);
		});
	}

	if (params.minRooms && params.maxRooms) {
		data = data.filter(item => {
			return (item.rooms >= params.minRooms && item.rooms <= params.maxRooms);
		});
	}

	if (params.pets === true) {
		data = data.filter(item => {
			return item.petsAllowed === true;
		});
	}
	if (params.balcony === true) {
		data = data.filter(item => {
			return item.propertyFacilities.includes('Balcony') || item.tenancyFacilities.includes('Balcony');
		});
	}
	if (params.elevator === true) {
		data = data.filter(item => {
			return item.propertyFacilities.includes('Balcony') || item.tenancyFacilities.includes('Balcony');
		});
	}

	return data;
};